@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mooli&family=Open+Sans:wght@300&family=Roboto:wght@300&display=swap");

@layer components {
  /* LOADING DIV */

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
:root {
  --blueish: #3a4fc8;
  --single-ticket-container-width: 64%;
  --properties-container-width: 240px;
  --top-bar-height: 50px;
  --dashboard-card-height: 100px;
  --bottom-bar-height: 40px;
  --vertical-nav-width: 50px;
  --contact-details-container-width: 240px;

  --light-color-border: rgba(155, 155, 155, 0.3);
}

* {
  padding: 0px;
  margin: 0;
  box-sizing: border-box;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.m-top-3 {
  margin-top: 3px;
}

.m-top-5 {
  margin-top: 5px;
}

.m-top-10 {
  margin-top: 10px;
}

.m-top-20 {
  margin-top: 20px;
}

.m-top-50 {
  margin-top: 50px;
}

.m-right-10 {
  margin-right: 10px;
}

.m-bottom-10 {
  margin-bottom: 10px;
}

.m-left-10 {
  margin-left: 10px;
}

.gap-5 {
  gap: 5px;
}

.padding-5-10 {
  padding: 5px 10px;
}

.bold {
  font-weight: bold;
}

.lighter-font {
  color: #adb0b6;
}

.light-font {
  color: rgba(50, 50, 50, 0.7);
}

.blueish-font {
  color: var(--blueish);
}

.standard-input {
  box-shadow: 1px 1px 5px rgba(200, 200, 200, 0.9);
  border-radius: 5px;
  height: 28px;
  font-size: 16px;
  padding: 5px 10px;
  border: 1px solid rgba(200, 200, 200, 0.9);
}

.standard-input:focus {
  outline: 1px solid rgb(25, 25, 25);
}

.standard-button {
  border-radius: 5px;
  border: 1px solid rgba(200, 200, 200, 0.9);
  height: 28px;
  line-height: 20px;
  text-align: center;
  padding: 5px 10px;
}

.error {
  text-align: center;
  border-radius: 5px;
  border: 1px solid #e7195a;
  background-color: #e7195a11;
  color: #e7195a;
  padding: 5px 10px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.flex {
  display: flex;
  align-items: center;
}

a {
  color: #000;
  text-decoration: none;
}

/* VERTICAL NAVBAR */

.vertical-nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: #12344d;
  width: var(--vertical-nav-width);
  height: 100vh;
  text-align: center;
  color: white;
}

.vertical-nav a {
  color: #ffffff;
  padding: 10px 0 10px 0;
}

.vertical-nav a:hover {
  background-color: #38556a;
}

/*LOGIN PAGE*/

.login-heading {
  font-size: 1.6rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 10px;
}

/***** TOP BAR *****/

.top-bar {
  position: relative;
  left: 50px;
  width: calc(100vw - var(--vertical-nav-width));
  height: var(--top-bar-height);
  line-height: 30px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(222, 222, 222, 0.7);
  background-color: #ffffff;
}

.new-button {
  border: 1px solid #cfd7df;
  border-radius: 5px;
  background-color: #fdfdfd;
  padding: 0 8px;
  color: black;
  height: 30px;
  line-height: 30px;
}

.new-button:hover {
  background-color: #dddddd;
}

.search-input {
  box-shadow: none;
  width: 216px;
  -webkit-transition: width 0.1s ease-out;
  -moz-transition: width 0.1s ease-out;
  -o-transition: width 0.1s ease-out;
  transition: width 0.1s ease-out;
}

.search-input:focus {
  width: 300px;
}

/******* BOTTOM BAR *******/

.bottom-bar {
  position: relative;
  left: 50px;
  height: var(--bottom-bar-height);
  line-height: 30px;
  width: calc(100vw - 50px);
  border-bottom: 1px solid rgba(222, 222, 222, 0.7);
  background-color: #f5f7f9;
  padding: 10px;
  box-shadow: 0 3px 3px -5px rgba(0, 0, 0, 0.5);
}

/* NEW TICKET */

/* input new ticket */

.new-ticket {
  background-color: #ebeff3;
}

.new-ticket-form {
  position: relative;
  margin-top: 10px;
  left: 60px;
  border: 1px solid rgba(222, 222, 222, 0.7);
  padding: 10px;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  -ms-overflow-style: none; /* hide scroll-bar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */
  width: calc(100vw - 280px);
  background-color: #ffffff;
  border-radius: 5px;
}

/* hide scroll bar in Chrome, Safari and Opera*/
.new-ticket-form::-webkit-scrollbar {
  display: none;
}

/* input new ticket */

.input-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
  margin-top: 5px;
  gap: 5px;
}

.input {
  border-radius: 3px;
  height: 30px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid rgba(200, 200, 200, 0.7);
  box-shadow: inset 0 0 1px gray;
}

.empty {
  border: 1px solid #e7195a;
}

/* text area */

.text-area-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
}

.text-area-container textarea {
  resize: none;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  height: 200px;
}

/* new tickets buttons */

.new-ticket-buttons {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 20px auto;
}

.new-ticket-buttons button {
  background-color: #fbfcfc;
  border-radius: 5px;
  color: #000000;
  border: 1px solid rgba(222, 222, 222, 0.7);
  padding: 5px 10px;
}

.create-button {
  margin-left: 10px;
  background-color: #224561 !important;
  color: #ffffff !important;
}

/*error new ticket */

.error-new-ticket {
  display: block;
  width: 70%;
  margin: auto;
  margin-bottom: 10px;
}

/*** MULTITAG ***/

.input-container-multitag {
  width: 100%;
  min-height: 20px;
  background-color: #fff;
  border-radius: 4px;
  text-align: left;
  border: 1px solid rgba(200, 200, 200, 0.7);
  padding: 5px 10px;
  cursor: text;
  line-height: 24px;
  box-shadow: inset 0 0 1px gray;
}

.input-container-multitag:focus-within {
  outline: 1px solid black;
}

.editable {
  display: inline-block;
  min-width: 5px;
  max-width: auto;
  height: 16px;
  line-height: 16px;
}

.editable:focus {
  outline: none;
}

.options {
  background-color: #fff;
  border-radius: 5px;
  min-height: 40px;
  max-height: 150px;
  margin-top: 5px;
  position: absolute;
  padding: 5px 10px;
  overflow: auto;
  width: 211px;
  box-shadow:
    0.5px 0.5px 5px #3334,
    -0.5px -0.5px 5px #3334;
}

/* options container for  single select component */

.options-single {
  /* width: 69%; */
  width: 69%;
  margin-top: 70px;
}

.options::-webkit-scrollbar {
  width: 8px;
}

.options::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.options::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}

.options::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0;
}

.option {
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option:hover {
  background-color: #dddddd;
  cursor: pointer;
}

.option-selected-container {
  display: inline-block;
  margin-right: 5px;
}

.option-selected {
  background-color: #ebeff2;
  padding: 3px 0 3px 3px;
  border-radius: 3px 0 0 3px;
  user-select: none;
}

.delete-button {
  background-color: #ebeff2;
  padding: 3px 3px 3px 3px;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.cross-icon {
  font-size: small;
}

.delete-button:hover {
  background-color: #dadee1;
  cursor: pointer;
}

.selected {
  background-color: rgba(229, 242, 253, 255);
  color: rgba(44, 92, 197, 200);
  user-select: none;
}

.check-icon {
  color: #2c5cc5;
}

.selected-container:empty + .editable:empty::before {
  content: "Any";
  color: rgb(170, 170, 170);
}

/* TICKETS */

.ticket-page {
  background-color: #ebeff3;
  height: 100vh;
}

.tickets-container {
  position: relative;
  left: 60px;
  top: 10px;
  width: calc(100vw - 360px);
  height: calc(100vh - 140px);
  overflow: auto;
  border: 1px solid rgba(222, 222, 222, 0.7);
  background-color: #ffffff;
  box-shadow: 0 0 5px -1px #0006;
}

.empty-message {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 10px;
}

/***** TICKET CARD *****/

.ticket-card-container {
  display: grid;
  padding: 5px 15px;
  grid-template-columns:
    200px
    400px
    150px
    150px
    150px
    80px;
  height: 60px;
  border-top: 1px solid rgba(222, 222, 222, 0.7);
  align-items: center;
}

.ticket-card-container:hover {
  background-color: #f3f5f7;
}

.ticket-card-container:first-child {
  border-top: none;
}

.ticket-card-container:last-child {
  border-bottom: 1px solid rgba(222, 222, 222, 0.7);
}

.ticket-card-container td input {
  margin: 0 10px 0 0;
}

table {
  width: 100%;
}

.subject-card:hover {
  color: var(--blueish);
}

/***** SINGLE TICKET *****/

.single-ticket-page {
  background-color: #eceff3;
}

.single-ticket-container {
  position: relative;
  box-shadow:
    -2px 0 3px #ddd,
    2px 0 3px #ddd;
  left: calc(20px + var(--vertical-nav-width));
  /* width: calc(100vw - 560px); */
  width: var(--single-ticket-container-width);
  height: calc(100vh - var(--top-bar-height));
  min-width: 600px;
  background-color: #ffffff;
  padding: 20px 30px;
  overflow: auto;
  -ms-overflow-style: none; /* hide scroll-bar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */
}

/* hide scroll bar in Chrome, Safari and Opera*/
.single-ticket-container::-webkit-scrollbar {
  display: none;
}

.subject {
  font-size: 1.3rem;
}

.description {
  margin-left: 40px;
}

/* Add note div and button */

.add-note-div {
  width: 100%;
  height: 40px;
  background-color: #f5f7f9;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.add-note {
  background-color: #fcfdfd;
  border: 1px solid rgba(222, 222, 222, 0.7);
  border-radius: 5px;
  color: #000000;
  height: 30px;
  line-height: 18px;
  padding: 5px 10px;
}

.add-note:hover {
  background-color: #ebecec;
  border: 1px solid rgb(190, 190, 190);
}

.properties-container {
  position: absolute;
  background: #fff;
  box-shadow:
    -2px 0 3px #ddd,
    2px 0 3px #ddd;
  top: var(--top-bar-height);
  left: calc(var(--single-ticket-container-width) + 75px);
  height: calc(100vh - 50px);
  width: var(--properties-container-width);
  padding: 5px 10px;
  overflow-y: auto;
}

.properties .input-container {
  width: 90%;
}

.status-properties {
  color: rgb(100, 100, 100);
  font-size: 1.2rem;
  font-weight: 300;
}

.title-properties {
  display: block;
  margin-top: 10px;
  border-top: 1px solid rgb(222, 222, 222);
  font-size: 1.3rem;
  padding: 5px;
}

@media screen and (max-width: 937.5px) {
  .properties-container {
    left: 675px;
  }
}

.update-button {
  display: block;
  width: 90%;
  margin: 10px auto;
  padding: 5px 10px;
  height: auto;
}

.update-button:disabled {
  cursor: not-allowed;
  background-color: #11334f55;
}

.update-button:disabled:hover {
  background-color: #11334f55;
  border: 1px solid #11334f55;
}

/** CONTACT DETAILS in Single ticket page **/

.contact-details-container {
  position: absolute;
  background: #fff;
  border: 1px solid var(--light-color-border);
  top: calc(var(--top-bar-height) + 10px);
  left: calc(
    var(--single-ticket-container-width) + var(--properties-container-width) +
      var(--vertical-nav-width) + 35px
  );
  width: var(--contact-details-container-width);
  border-radius: 5px;
  height: calc(100vh - var(--top-bar-height) - 20px);
  overflow-y: auto;
}

.contact-details-heading {
  background-color: #f5f7f9;
  border-radius: 5px 5px 0px 0px;
  gap: 5px;
  padding: 10px 20px;
  border-bottom: 1px solid var(--light-color-border);
}

.contact-details-content {
  padding: 10px;
}

.email-heading {
  color: #adb0b6;
  margin: 15px 0px 5px 0;
}

.view-more-link {
  color: #4f69c1;
  margin-top: 15px;
  display: flex;
  gap: 3px;
  align-items: center;
}

/* TIMELINE */

.timeline-container {
  position: relative;
  height: 60%;
}

.timeline-item {
  display: flex;
}

.timeline-separator {
  margin-right: 10px;
}

.timeline-connector {
  border-right: 1px solid #deeaf8;
  height: 100%;
  width: 1px;
  margin: 0 auto;
  z-index: 0;
}

.dot {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  /* border: 1px solid #9b9b9b4d; */
  border: 1px solid #deeaf8;
  z-index: 1;
  background-color: #eceff3;
}

/***** ADDING NOTE PAGE *****/

.button-default {
  background-color: #fcfdfd;
  border: 1px solid rgba(222, 222, 222, 0.7);
  border-radius: 5px;
  color: #000000;
  height: 30px;
  padding: 5px 10px;
  line-height: 18px;
}

.button-default:hover {
  background-color: #ebecec;
  border: 1px solid rgb(190, 190, 190);
}

.button-default:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.dark-bc {
  background-color: #21435f;
  /* background-color: #21435f; */
  color: white !important;
}

.dark-bc:hover {
  background-color: #11334f;
  border: 1px solid rgb(0, 31, 59);
}

.dark-bc:active {
  transition: background-color 0.1s;
  background-color: #00223e;
  scale: 0.97;
}

.adding-note-bottom-bar {
  width: 100%;
  height: 40px;
  background-color: #f5f7f9;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid rgba(222, 222, 222, 0.7);
}

.note-textarea-container {
  height: 120px;
}

.note-textarea {
  width: 100%;
  resize: none;
  height: 100%;
  padding: 10px;
  border: 1px solid rgba(222, 222, 222, 0.7);
  border-bottom: 0;
}

.note-textarea:focus {
  outline: none;
}

/***** NOTE *****/

.note {
  background-color: #fef1e1;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid rgba(222, 222, 222, 0.3);
}

.note:hover .more-vert {
  opacity: 1;
}

.note .more-vert {
  transition: opacity 0.1s;
}

.more-vert {
  font-size: 1.3rem !important;
  color: gray;
  cursor: pointer;
  opacity: 0;
}

.small-lighter {
  font-size: 12px;
  color: rgba(50, 50, 50, 0.7);
}

.note-edited {
  margin-left: 40px;
  font-size: 14px;
  font-style: italic;
}

.note-content {
  margin-left: 40px;
  width: calc(100% - 40px);
  font-size: 15px;
  resize: none;
  overflow: hidden;
}

.fixed-note {
  border: none;
  background-color: #fef1e1;
}

.fixed-note:focus {
  outline: none;
}

.note-buttons {
  margin-left: 40px;
}

/***** DASHBOARD *****/

.dashboard-container {
  background-color: #ebeff3;
  height: 100vh;
  width: 100vw;
}

/* DASHBOARD CARD */

.dashboard-card-container {
  position: relative;
  top: 10px;
  left: 60px;
  width: calc(100vw - 60px - 10px);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.dashboard-card {
  height: var(--dashboard-card-height);
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px rgba(222, 222, 222, 0.7);
}

/* TODO */

.todo-container {
  height: 300px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgba(222, 222, 222, 0.7);
  padding: 10px;
}

.todo-heading {
  padding: 0 10px;
  margin-bottom: 5px;
}

.todo-input {
  border-radius: 0;
  border: 0 black solid;
  border-bottom: 1px rgba(222, 222, 222, 0.7) solid;
  width: 100%;
  padding: 3px 10px;
  display: flex;
  gap: 5px;
}

.todo-input:has(> input:focus) {
  border-bottom: 1px solid rgba(94, 186, 125, 0.7);
}

.todo-input input {
  border: none;
  width: 100%;
  font-size: 14px;
}

.todo-input input:focus {
  outline: 0;
}

.add-todo {
  cursor: pointer;
}

.todo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  font-size: 18px;
  border-bottom: 1px solid rgba(222, 222, 222, 0.7);
}

.todo input[type="checkbox"] {
  accent-color: #1a4d73;
}

.todo .delete-todo {
  color: #1a4d73;
  margin-right: 20px;
  opacity: 0;
  transition: opacity 0.1s;
  cursor: pointer;
}

.todo:hover > .delete-todo {
  opacity: 1;
}

.todos {
  overflow: auto;
  height: 230px;
}

.check-todo {
  width: 16px;
}

.check-todo:checked + p {
  text-decoration: line-through;
  color: rgb(150, 150, 150);
}

/********/

.todo-activities-container {
  position: relative;
  width: calc(100vw - 60px - 10px);
  height: calc(100vh - 50px - 40px - 100px - 40px);
  left: 60px;
  top: 20px;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 2fr;
}

.activities-dashboard-container {
  background-color: #fff;
  height: calc(
    100vh - var(--top-bar-height) - var(--bottom-bar-height) -
      var(--dashboard-card-height) - 50px
  );
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgba(222, 222, 222, 0.7);
  padding: 15px;
  overflow: auto;
}

.activity {
  padding: 10px;
  border-bottom: 1px solid rgba(210, 210, 210, 0.7);
  display: flex;
  gap: 10px;
}

.profile-image {
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.3);
  user-select: none;
}

.profile-img-clickable {
  cursor: pointer;
}

.profile-img-clickable:active {
  background-color: blue;
  transform: scale(1.05);
}

.profile-img-nonclickable:hover {
  cursor: default;
}

.profile-image-submenu {
  position: absolute;
  border-radius: 5px;
  border: 1px solid var(--light-color-border);
  background-color: #fff;
  width: 200px;
  top: calc(var(--top-bar-height) - 9px);
  right: 5px;
  z-index: 1;
}

.pi-small {
  height: 30px;
  width: 30px;
  line-height: 30px;
}

.pi-medium {
  height: 50px;
  width: 50px;
  line-height: 50px;
}

.pi-large {
  height: 70px;
  width: 70px;
  line-height: 70px;
}

.ticket-link {
  color: #2c80d6;
}

/******* FILTERS *******/

.filters {
  position: absolute;
  top: calc(var(--top-bar-height) + 10px);
  right: 0;
  width: 270px;
  height: calc(100vh - 90px);
  overflow-y: auto;
  border: 1px solid rgba(222, 222, 222, 0.7);
  padding: 10px 20px;
  box-shadow: -2px 0 10px -5px rgba(0, 0, 0, 0.5);
}

.submit-filters {
  background-color: #173a54;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 20px;
  width: 100%;
}

.submit-filters:hover {
  background-color: #284b65;
  cursor: pointer;
}

/***** CONTACTS PAGE *****/

.table {
  display: table;
}

.table-row {
  display: table-row;
  height: 50px;
}

.table-cell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.contacts-page {
  background-color: #ebeff3;
  min-height: 560px; /* top bar height + top positioning + min height of table container  */
  height: 100vh;
}

.table-container {
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid var(--light-color-border);
  box-shadow:
    0 0 2px #ddd,
    2px 0 2px #ddd;
  height: calc(100vh - var(--top-bar-height) - 40px);
  left: calc(var(--vertical-nav-width) + 80px);
  min-height: 500px;
  overflow-y: auto;
  position: relative;
  top: 10px;
  width: calc(100vw - var(--vertical-nav-width) - 80px - 80px);
}

.contacts-table {
  width: 100%;
}

.contacts-heading {
  position: sticky;
  top: 0;
  height: 50px;
  width: 100%;
  background-color: #f5f7f9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid var(--light-color-border);
}

.contacts-heading > .table-cell:first-child {
  border-top-left-radius: 10px;
}

.contacts-heading > .table-cell:last-child {
  border-top-right-radius: 10px;
}

a.table-row:hover {
  background-color: #eee;
}

.table a.table-row:nth-child(odd) {
  background-color: #f5f5f5;
}

.table a.table-row:nth-child(odd):hover {
  background-color: #eee;
}

/******* CONTACT PAGE *******/

.contact-page {
  background-color: #eceff3;
}

.contact-container {
  background-color: #ffffff;
  box-shadow:
    0 0 3px #ddd,
    2px 0 3px #ddd;
  height: calc(100vh - var(--top-bar-height) - var(--bottom-bar-height));
  left: calc(var(--vertical-nav-width) + 80px);
  min-height: 500px;
  overflow-y: auto;
  padding: 20px 50px;
  position: relative;
  width: calc(100vw - var(--vertical-nav-width) - 80px - 80px);
}

/***** HOME *****/

.logo-home {
  font-family: "Mooli", sans-serif;
}

.items-home {
  font-family: "Roboto", sans-serif;
}
